import { CommonModule } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from "@angular/forms";

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { RstDashboardService } from "../../_services/rst-dashboard.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { SocketServiceService } from "../../socket-service.service";
import { CacheServiceService } from "../../cache-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TaxSummaryService } from "../../tax-summary.service";
import { environment } from "../../../environments/environment";
import { map, Observable, startWith } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table"; // Import MatTableModule
import { Notyf } from "notyf";
import { MatExpansionPanel } from "@angular/material/expansion";
import { SharedModule } from "../../common.module";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});

@Component({
  selector: "app-dashboard-invoice",
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatTableModule,
  ],

  templateUrl: "./dashboard-invoice.component.html",
  styleUrl: "./dashboard-invoice.component.css",
})
export class DashboardInvoiceComponent {
  categoryObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  tableId: any; // Variable to store the table ID
  customerForm: FormGroup;
  listNumber: string[] = []; // Initialize with some data if available
  additionalData: any = null; // New variable to store additional data
  grandTotal: number | null = null; // Variable to store grand total
  kotItems: any[] = []; // Variable to store KOT items
  itemsArr: any = [];
  eBillsForm: FormGroup;
  validationForm: FormGroup;
  currency: any;
  table_id: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild("panel") panel!: MatExpansionPanel;
  @ViewChild("panel3") panel3!: MatExpansionPanel;
  @ViewChild("panel4") panel4!: MatExpansionPanel;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  filteredOptions: Observable<any[]> = new Observable<any>();
  itemControl = new FormControl();
  tableDetails: any;
  selectedTable: any;
  take_away = true;
  dine_in = true;
  water: boolean = false;
  waiter: boolean = false;
  notesValueForEdit: any;
  disposalItemData: any;
  disposallist: any;
  disposalItemlist: any;
  disposalItemToggle = false;
  showValidationInput = false;
  showValidationDiscount = false;
  isTransactionGSTslab: boolean = false;
  _generating_sales_order: boolean = false;
  amount: any;
  totalAmt: any;
  tax: any = [];
  amouunt: any;
  invoice_no: any;

  totalTax: any;
  totalNetAmt: any;
  roundOffAmt: any;
  totalDis: any;
  totalSGST: any;
  totalCGST: any;
  totalIGST: any;
  ledger_id: any;
  sgstUnqPer: any = [];
  igstUnqPer: any = [];
  sign: any;
  dataObj: any = {};
  transDataObj: any = { items_details: [] };
  otpVerified = false;
  otpInvalid = false;
  non_receivable = false;
  formSubmitted = false;
  order_tab: any = "Dine-In";
  sub_category_data: any;
  subCatArr = [];
  getItems: boolean = false;
  getCategory: boolean = false;
  getSubCategory: boolean = false;
  getDisposalitems: boolean = false;
  getDisposalCategory: boolean = false;
  isLocal: boolean = false;
  tableInfo: any;
  tableCapacity: any = 0;
  optionarray_amount: number = 0;
  options_array: any[] = [];
  checkedVariant: any[] = [];
  checkedVariantAmount: number = 0;
  connection: any;
  selectedCategoryName: any = null;
  ebillData: any = [];
  menuItemsWithPriceList: any = [];
  isEbillingEnable: any = "false";
  catArr: any;
  itemArr: any;
  tableObj: any;
  mapArr: any;
  soIdArr: any = [];
  kotArr: any = [];
  dueInvoice: any;
  isdiscountFocused: boolean = false;
  dueInvoiceObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  isAutoFocus: boolean = false;
  
  constructor(
    private apiService: RstDashboardService,
    private _Activatedroute: ActivatedRoute,
    private socketService: SocketServiceService,
    public dialog: MatDialog,
    // private notifier: NotifierService,
    private cacheService: CacheServiceService,
    private spinner: NgxSpinnerService,
    private taxService: TaxSummaryService,
    private router: Router,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DashboardInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currency = environment.currency;
    _Activatedroute.paramMap.subscribe((params) => {
      if (params.get("id")) {
        this.table_id = params.get("id");
      }
    });

    this.validationForm = this.fb.group({
      validationCode: new FormControl(""),
    });

    this.eBillsForm = this.fb.group({
      selectedEbillNames: new FormControl([]),
    });
    // this.validationForm = this.fb.group({ });
    // this.eBillsForm  = this.fb.group({ });
    // this.form  = this.fb.group({ });

    // Initialize filtered options
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterItems(value))
    );
    this.customerForm = this.fb.group({
      contact_no: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
      customer_name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
    });
  }

  form: FormGroup = new FormGroup({
    customer_name: new FormControl("Name"),
    email: new FormControl(null),
    trans_no: new FormControl("Auto Generated"),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null),
    table_id: new FormControl(null),
    gstin: new FormControl(null),
    total_amount: new FormControl(0, Validators.required),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0, Validators.required),
    cash: new FormControl(0, Validators.required),
    card: new FormControl(0, Validators.required),
    upi: new FormControl(0, Validators.required),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    balance: new FormControl(0),
    contact_no: new FormControl(null),
    taxslab: new FormControl(0),
    vattaxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    vat_per: new FormControl(0),
    vat_amt: new FormControl(0),
    gross_amount: new FormControl(0),
    item_qty: new FormControl(null),
    item_rate: new FormControl(null),
    item_amt: new FormControl(null),
    cash_receive: new FormControl(0),
    upi_receive: new FormControl(0),
    instruction: new FormControl(""),
  });

  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      customer_name: "Name",
      email: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      table_id: null,
      gstin: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      balance: 0,
      contact_no: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      vat_per: 0,
      vat_amt: 0,
      gross_amount: 0,
      taxslab: 0,
      vattaxslab: 0,
      item_qty: null,
      item_rate: null,
      item_amt: null,
      cash_receive: 0,
      upi_receive: 0,
      instruction: null,
    });
    this.itemsArr = [];
    this.calculateAmtWithTransTax();
    this.soIdArr = [];
    this.kotArr
      .filter((x: any) => x.added === true)
      .forEach((x: any) => this.kotArr.splice(this.kotArr.indexOf(x), 1));
    // this.allow_login = false;
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    // Extract table ID from data and store it in tableId
    this.tableId = this.data.table.id;
    this.load();
  }

  load() {
    this.tableInfoFun();
  }

  getEbillingdata() {
  }

  filterItems(query: string): any[] {
    return this.itemsArr.filter((item: any) =>
      item.item_name.toLowerCase().includes(query)
    );
  }

  tableInfoFun() {

    const data = [];
    data.push({
      getItems: this.getItems,
      getCategory: this.getCategory,
      getSubCategory: this.getSubCategory,
      getDisposalitems: this.getDisposalitems,
      getDisposalCategory: this.getDisposalCategory,
    });
    this.spinner.show();
    this.apiService.getTableInfo(this.tableId, data).subscribe(
      async (result: any) => {
        this.spinner.hide();
        this.tableInfo = result[0];
       
        //---------mapArr----------------------------------------------
        if (this.tableInfo.accountMapList) {
          this.mapArr = this.tableInfo.accountMapList;
          if (this.mapArr) {
            this.findAccSetting();
          }
        }
       

        //---------TableListWithKOT------------------------------------
        this.tableObj = this.tableInfo.kot;
        if (this.tableObj) {
          this.tableObj.forEach((kot: any) => {
            kot.added = true;
            this.soIdArr.push(kot.transaction_id);
            this.kotArr.push(JSON.parse(JSON.stringify(kot)));
            kot.items_details.forEach((item: any) => {
              var pos = this.itemsArr.findIndex(
                (e: any) =>
                  e.item_id === item.item_id && e.s_rate == item.s_rate
              );
              if (pos == -1) {
                this.itemsArr.push(item);
              } else {
                this.itemsArr[pos].qty =
                  parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
                this.itemsArr[pos].amount =
                  parseFloat(this.itemsArr[pos].qty) *
                  parseFloat(this.itemsArr[pos].s_rate);
              }
            });
          });
          this.calculateAmtWithTransTax();
        }

        //---------UnpaidInvoice----------------------------------------
        if (this.tableInfo.unpaidInvoiceList) {
          this.dueInvoice = this.tableInfo.unpaidInvoiceList;
          this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
          this.dueInvoiceObj.sort = this.sort;
          this.dueInvoiceObj.paginator = this.paginator;
        } else {
          this.dueInvoiceObj = new MatTableDataSource();
        }
      },
      (result: any) => {
        notyf.error("unable to load data");
      }
    );
  }
  onChangeItemQty(e: any, i: any) {
    this.itemsArr[i].qty = e.target.value || 1;
    this.itemsArr[i].amount = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    // this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
  }

  onDeleteItem(i: any) {
    this.itemsArr.splice(i, 1);
    this.calculateAmtWithTransTax();
  }

  onDiscountFocus(isFocused: boolean) {
    this.isdiscountFocused = isFocused;
    if (!isFocused) {
      const discountControl: any = this.form.get("dis_per");
      if (discountControl.value === 0) {
        discountControl.setValue(null);
      }
    }
  }

  onDiscountPer(event: any) {
    var discount = 0;
    discount = this.form.value.total_amount * (this.form.value.dis_per / 100);
    discount = Math.round(discount * 100) / 100;
    this.form.get("discount_amt")!.setValue(discount);
    this.calculateAmtWithTransTax();
  }

  onDiscountAmt(event: any) {
    this.form.get("dis_per")!.setValue(0);
    this.calculateAmtWithTransTax();
  }

  onOtherPrice() {
    if (this.form.value.total_amount) {
      this.calculateAmtWithTransTax();
    }
  }
  convertFloat(val: any) {
    return parseFloat(val);
  }

  waitforinvoice: boolean = false;
  waitforkot: boolean = false;
  waitforTakeaway: boolean = false;

  calculateAmtWithTransTax() {
    if (this.isTransactionGSTslab === false) {
      this.amount = 0;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0,
        vat_per = 0,
        vat_amt = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      for (var i = 0; i < this.itemsArr.length; i++) {
        this.amount += this.itemsArr[i].amount;
        if (this.itemsArr[i].tax_mode === "GST") {
          sgst_amt += this.itemsArr[i].sgst_amt || 0;
          cgst_amt += this.itemsArr[i].cgst_amt || 0;
          igst_amt += this.itemsArr[i].igst_amt || 0;
        } else {
          vat_amt += this.itemsArr[i].vat_amt || 0;
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;

      this.totalTax = parseFloat((sgst_amt + cgst_amt + igst_amt + vat_amt).toFixed(2));
      this.totalAmt = parseFloat((taxable_amt + this.totalTax + this.form.value.other1_amt + this.form.value.other2_amt).toFixed(2));
      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffAmt < 0.5) {
        this.totalNetAmt = this.totalAmt - this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.roundOffAmt = -this.roundOffAmt;
        this.sign = "fa-minus";
      } else {
        this.roundOffAmt = 1 - this.roundOffAmt;
        this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
        this.totalNetAmt = this.totalAmt + this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.sign = "fa-plus";
      }

      this.form.get("total_amount")?.setValue(this.amount);
      this.form.get("net_amount")?.setValue(this.totalNetAmt);
      this.form.get("roundoff_amt")?.setValue(this.roundOffAmt);
      this.form.get("gross_amount")?.setValue(this.totalAmt);
      this.form.get("total_tax")?.setValue(this.totalTax);
      this.form.get("sgst_per")?.setValue(sgst_per);
      this.form.get("sgst_amt")?.setValue(sgst_amt);
      this.form.get("cgst_per")?.setValue(cgst_per);
      this.form.get("cgst_amt")?.setValue(cgst_amt);
      this.form.get("igst_per")?.setValue(igst_per);
      this.form.get("igst_amt")?.setValue(igst_amt);
      this.form.get("vat_per")?.setValue(vat_per);
      this.form.get("vat_amt")?.setValue(vat_amt);
      this.form.get("taxable_amt")?.setValue(taxable_amt);
    } else {
      this.amount = 0;
      var tax_slab = this.form.value.taxslab;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      let vatAmount = 0;
      let vatTaxAmount = 0;
      for (var i = 0; i < this.itemsArr.length; i++) {
        if (this.itemsArr[i].tax_mode === "GST") {
          this.amount = this.amount + this.itemsArr[i].amount;
        } else {
          this.itemsArr[i].vat_per = this.itemsArr[i].taxslab;
          this.itemsArr[i].vat_amt =
            this.itemsArr[i].amount * (this.itemsArr[i].vat_per / 100) || 0;
          this.itemsArr[i].vat_amt =
            Math.round(this.itemsArr[i].vat_amt * 100) / 100;
          this.itemsArr[i].total_tax = this.itemsArr[i].vat_amt || 0;

          this.itemsArr[i].total =
            this.itemsArr[i].amount + this.itemsArr[i].total_tax;
          vatTaxAmount += this.itemsArr[i].total_tax;
          vatAmount += this.itemsArr[i].amount;
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;
      sgst_per = tax_slab / 2;
      sgst_amt = taxable_amt * (sgst_per / 100);
      sgst_amt = Math.round(sgst_amt * 100) / 100;
      cgst_per = tax_slab / 2;
      cgst_amt = taxable_amt * (cgst_per / 100);
      cgst_amt = Math.round(cgst_amt * 100) / 100;

      tax_amt = sgst_amt + cgst_amt;
      this.totalTax = parseFloat((sgst_amt + cgst_amt + igst_amt + vatTaxAmount).toFixed(2));

      this.totalAmt =
        taxable_amt +
        vatAmount +
        tax_amt +
        vatTaxAmount +
        this.form.value.other1_amt +
        this.form.value.other2_amt;
      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffAmt < 0.5) {
        this.totalNetAmt = this.totalAmt - this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.roundOffAmt = -this.roundOffAmt;
        this.sign = "fa-minus";
      } else {
        this.roundOffAmt = 1 - this.roundOffAmt;
        this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
        this.totalNetAmt = this.totalAmt + this.roundOffAmt;
        this.totalNetAmt = Math.round(this.totalNetAmt);
        this.sign = "fa-plus";
      }

      this.form.get("total_amount")?.setValue(this.amount + vatAmount);
      this.form.get("net_amount")?.setValue(this.totalNetAmt);
      this.form.get("roundoff_amt")?.setValue(this.roundOffAmt);
      this.form.get("gross_amount")?.setValue(this.totalAmt);
      this.form.get("total_tax")?.setValue(this.totalTax);
      this.form.get("sgst_per")?.setValue(sgst_per);
      this.form.get("sgst_amt")?.setValue(sgst_amt);
      this.form.get("cgst_per")?.setValue(cgst_per);
      this.form.get("cgst_amt")?.setValue(cgst_amt);
      this.form.get("igst_per")?.setValue(igst_per);
      this.form.get("igst_amt")?.setValue(igst_amt);
      this.form.get("taxable_amt")?.setValue(taxable_amt + vatAmount);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.saveInvoiceData("submit");
  }

  SaveAndPrint() {
    this.saveInvoiceData("print");
  }

  SaveAndEbill() {
    this.saveInvoiceData("ebill");
  }

  private saveInvoiceData(action: string) {
    // Common logic to save invoice data
    // this.saveContact();
    if (this.showValidationInput) {
      if (this.validationForm.valid) {
        this.otpVerified = false;
        this.otpInvalid = false;
        this.formSubmitted = true;
        this.showValidationInput = false;
        this.showValidationDiscount = false;
        this.Savedata(action);
      }
    } else {
      this.Savedata(action);
    }
  }
  reset() {
    this.itemsArr = [];
    this.soIdArr = [];
    this.kotArr = [];
    this.load();
  }

  connectSocket() {
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
        this.socketService.emit("socketIds", {
          company_id: localStorage.getItem("company_id"),
        });
        this.socketService.on("getSocketIds").subscribe((result: any) => {
        });
        if (!this._generating_sales_order) {
          this.apiService.getTableByTransId(result.transaction_id).subscribe(
            (res: any) => {
              if (res[0].table_id == this.table_id) {
                this.reset();
              }
            },
            (error) => {}
          );
        }
      });
  }
  private Savedata(action: string) {
    this.waitforinvoice = true;
    let orderMode = this.order_tab === "Take Away" ? "Take Out" : "Dine In";

    // Conditionally modify eBillData
    let eBillData;
    if (action === "print") {
      // For printing action
      eBillData = this.ebillData.map((item: any) => ({
        ...item,
        checkbox_value: false,
      }));
    } else {
      // For eBilling action (including default case for 'submit' action)
      eBillData = this.ebillData;
    }

    if (this.form.valid) {
      this.invoice_no = null;
      this.form.get("trans_no")!.setValue(this.invoice_no);

      this.transDataObj = this.form.value;
      this.transDataObj.items_details = this.itemsArr;
      this.transDataObj.soIdArr = this.soIdArr;
      this.transDataObj.ledger_id = 0;
      this.transDataObj.table_id = this.tableId;
      this.transDataObj.sale_type = "cash";
      this.transDataObj.orderMode = orderMode;
      this.transDataObj.non_Receivable = this.non_receivable;
      this.transDataObj.eBillData = eBillData;
      // if (this.non_receivable) {
      //   this.form.value.net_amount = 0;
      // }
      this.apiService.addInvoice(this.transDataObj).subscribe(
        (result: any) => {
          if (result.success) {
            this.form.value.net_amount = result.net_amount;
            this.form.value.balance_amt = result.balance_amt;
            this.form.value.total_amount = result.total_amount;
            this.form.value.balance = result.balance;
            this.form.value.taxable_amt = result.taxable_amt;
            this.transDataObj.transaction_id = result.transaction_id;
            this.transDataObj.display_trans_no = result.display_trans_no;
            this.transDataObj.trans_type = "Invoice";
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.initializeFormGroup();
            if (action === "ebill" || action === "print") {
              this.apiService.downloadPdf(this.transDataObj).subscribe(
                (result: any) => {
                  let blob = new Blob([result], {
                    type: "application/pdf",
                  });
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download =
                    "invoice" + this.transDataObj.display_trans_no + ".pdf";
                  link.click();
                  window.URL.revokeObjectURL(link.href);
                },
                (result: any) => {}
              );
            }
            notyf.success("New Invoice added successfully");
            if (this.form.controls["advance"].value) {
              this.initializeFormGroup();
              this.load();
              this.data = undefined;
            }
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.onClose();
          } else {
            window.scroll(0, 0);
            notyf.error(result.message);
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
          }
        },
        (result: any) => {
          this.initializeFormGroup();
          notyf.error("unable to add invoice");
          this.waitforinvoice = false;
          this.non_receivable = false;
          this.showValidationInput = false;
          this.showValidationDiscount = false;
          this.formSubmitted = false;
          this.validationForm.reset();
          this.validationForm.get("validationCode")!.setValue("");
        }
      );
    } else {
      window.scroll(0, 0);
      this.waitforinvoice = false;
      this.non_receivable = false;
      this.showValidationInput = false;
      this.showValidationDiscount = false;
      this.validationForm.reset();
      this.formSubmitted = false;
      this.validationForm.get("validationCode")!.setValue("");
    }
  }

  resetOTPFields() {
    this.validationForm.reset();
    this.otpVerified = false;
    this.otpInvalid = false;
    this.showValidationDiscount = false;
  }

  onCheckboxChange() {
    if (this.form.controls["dis_per"].value === 0 || null) {
      if (!this.showValidationInput) {
        this.apiService.ncValidation().subscribe(
          (response: any) => {
            this.showValidationDiscount = false;
            this.non_receivable = true;
          },
          (error: any) => {
            console.error("API error:", error);
          }
        );
      } else {
        this.non_receivable = false;
      }
    } else {
      notyf.error("Select either Discount or No Cost");
    }
    this.showValidationInput = !this.showValidationInput;
    this.showValidationDiscount = !this.showValidationDiscount;
    if (!this.showValidationInput) {
      this.resetOTPFields();
    }
  }


  

  findAccSetting() {
    var takeAway = this.mapArr.filter((e: any) => {
      return e.key === "Take_Away_Table";
    });
    if (takeAway[0].template_value === this.table_id) {
      (this.dine_in = false), (this.order_tab = "Take Away");
    }

    this.mapArr.filter((e: any) => {
      if (e.key === "Transaction_gstslab") {
        this.isTransactionGSTslab = e.checkbox_value;
      }
    });

    if (this.isTransactionGSTslab) {
      var indexofTrSlabValue = this.mapArr.findIndex(
        (i: any) => i.key === "gstslab_value"
      );

      this.form
        .get("taxslab")
        ?.setValue(this.mapArr[indexofTrSlabValue].template_value);
    }
    var indexofautofocus = this.mapArr.findIndex(
      (i: any) => i.key === "Enable_Auto_Focus"
    );

    if (this.mapArr[indexofautofocus].checkbox_value) {
      this.isAutoFocus = true;
    }
  }
}
