import { Routes } from '@angular/router';
import { ElectronLoginComponent } from './electron-login/e-login.component';
import { AppNavComponent } from './app-nav/app-nav.component';
import { RstInvoiceComponent } from './rst-dashboard/rst-invoice/rst-invoice.component';
import { RstKeyboardComponent } from './rst-dashboard/rst-keyboard/rst-keyboard.component'
import { RstDashboardComponent } from './rst-dashboard/rst-dashboard.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AuthGuard } from './guards/auth.guard';
import { AddEditPriceListComponent } from './items/items-price-list/add-edit-price-list/add-edit-price-list.component';
import { TenentManagementComponent } from './tenent-management/tenent-management.component';
import { TenentNavComponent } from './tenent-nav/tenent-nav.component';
import { RedirectComponent } from './redirect/redirect.component';
import { UpdateLicenceComponent } from './update-licence/update-licence.component';
import { TenentLogoutComponent } from './tenent-logout/tenent-logout.component';
import { TenentLoginComponent } from './tenent-login/tenent-login.component';
import { ElectronLogoutComponent } from './electron-logout/e-logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogoutComponent } from "./logout/logout.component";

export const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'admin', redirectTo: '/tenent-login', pathMatch: 'full' },
  {
    path: 'login',
    component: ElectronLoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  // {
  //   path: 'logout',
  //   component: ElectronLogoutComponent,
  // },
  {
    path: 'tenent-login',
    component: TenentLoginComponent,
  },
  {
    path: 'tenent-logout',
    component: TenentLogoutComponent,
  },
  {
    path: 'update-licence',
    component: UpdateLicenceComponent,
  },
  {
    path: 'redirect',
    component: AppNavComponent,
  },
  {
    path: 'redirecturl',
    component: RedirectComponent,
  },
  {
    path: 'admin',
    component: TenentNavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'tenent-management',
        component: TenentManagementComponent,
      },
    ],
  },
  {
    path: '',
    component: AppNavComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '/logout',
        component: LogoutComponent,
      },
      {
        path: 'Entry',
        loadChildren: () => import('./_modules/entry-modules/entry-modules.module').then(m => m.EntryModulesModule),
        data: { preload: true, delay: true },
      },
      {
        path: 'Transaction',
        loadChildren: () => import('./_modules/transaction-modules/transaction-modules.module').then(m => m.TransactionModulesModule),
        data: { preload: false, delay: false },
      },
      {
        path: 'Master',
        loadChildren: () => import('./_modules/master-modules/master-modules.module').then(m => m.MasterModulesModule),
        data: { preload: false, delay: false },
      },
      {
        path: 'Report',
        loadChildren: () => import('./_modules/reports-modules/reports-modules.module').then(m => m.ReportsModulesModule),
        data: { preload: true, delay: true },
      },
      {
        path: 'Setting',
        loadChildren: () => import('./_modules/setting-modules/setting-modules.module').then(m => m.SettingModulesModule),
        data: { preload: false, delay: false },
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      
      {
        path: 'rst-dashboard',
        component: RstDashboardComponent,
      },
      {
        path: 'rst-invoice/:id',
        component: RstInvoiceComponent,
      },
      {
        path: 'rst-keyboard/:id',
        component: RstKeyboardComponent,
      },
      {
        path: 'Master/add-item-editPrice',
        component: AddEditPriceListComponent,
      },
      {
        path: 'Master/add-item-editPrice/:id',
        component: AddEditPriceListComponent,
      },
    ],
  },
  { path: '**', redirectTo: 'access-denied' },
];