<div class="panelcard1">
  <h3 class="color-h3">Kot View</h3>
  <div class="table-details">
    <div class="row">
      <div class="table-view-list">
      <table class="table item-tbl">
        <thead>
          <tr>
            <th>#</th>
            <th class="itm-name">Item Name</th>
            <th></th>
            <th class="text-center">Qty</th>
            <th></th>
            <th class="right">Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of kotItems; let i = index">
            <td>{{ i + 1 }}</td>
            <td class="itm-name">{{ item.name }}</td>
            <td></td>
            <td class="text-center">{{ item.qty | number : "1.0-0" }}</td>
            <td></td>
            <td class="right">{{ item.s_rate | currency : "INR" }}</td>
            <td></td>
          </tr>
          <!-- Row for Grand Total -->
          <tr *ngIf="grandTotal !== null">
            <td colspan="5" class="text-right">Grand Total With Tax:</td>
            <td class="right">{{ grandTotal | currency : "INR" }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

      <!-- Container for actions -->
      <mat-dialog-actions class="dialog-actions">
        <button class="btn mat-raised-button" (click)="onClose()">Close</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
