import { APP_INITIALIZER,ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { QRCodeModule } from 'angularx-qrcode';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

// import { LicenceInfoComponent } from './licence-info/licence-info.component';
// import { NgxQRCodeModule } from 'ngx-qrcode2';



  // Define your custom date formats
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
  
@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxSpinnerModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,    
        MatFormFieldModule,
        MatIconModule,
        // NgxQRCodeModule
        QRCodeModule

    ],
    exports: [
        CommonModule,
        MaterialModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        NgxSpinnerModule,
        RouterModule,
        MatFormFieldModule,
        MatIconModule
        
        // LicenceInfoComponent,
        // NgxQRCodeModule
    ],
    providers: [
      {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        }, {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
      ],
    // declarations: [LicenceInfoComponent],
})
export class SharedModule { }