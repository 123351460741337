import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment";
import { DataService } from "../data.service";
import { SocketServiceService } from "../socket-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { timer } from "rxjs";
import { Subject } from "rxjs";
import { SharedModule } from "../common.module";
import { OrderConfirmationComponent } from "../app-nav/order-confirmation/order-confirmation.component";
import { CallRiderComponent } from "../app-nav/call-rider/call-rider.component";
import { RstDashboardService } from "../_services/rst-dashboard.service";

import { Notyf } from "notyf";
import { KotViewComponent } from "./kot-view/kot-view.component";
import { DashboardInvoiceComponent } from "./dashboard-invoice/dashboard-invoice.component";
import { ReceiptDialogComponent } from "./receipt-dialog/receipt-dialog.component";

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});

@Component({
  selector: "app-rst-dashboard",
  templateUrl: "./rst-dashboard.component.html",
  styleUrls: ["./rst-dashboard.component.css"],
  standalone: true,
  imports: [SharedModule],
})
export class RstDashboardComponent implements OnInit {
  kotBackgroundColor: string = "";
  tableArr: any = [];
  currency: any;
  kotArr: any;
  tableGroup: any = [];
  showOrders: boolean = false;
  webappActiveTable: boolean = false;
  private timerSubject: Subject<number> = new Subject<number>();
  timerValue: number = 0;
  private timerSubscription: any;
  timer: any;
  processingTimeinput: string = "00:15";

  timePickers: { [key: string]: boolean } = {};
  times: string[] = [
    "00:00",
    "00:05",
    "00:10",
    "00:15",
    "00:30",
    "00:45",
    "01:00",
    "01:15",
    "01:30",
    "01:45",
    // Add more time options as needed
  ];
  invData: any;

  constructor(
    public dialog: MatDialog,
    private apiService: RstDashboardService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private socketService: SocketServiceService // private notifier: NotifierService
  ) {
    this.currency = environment.currency;

    // notyf.error("Pending Approved");
  }
  orderTypes: any = [
    { name: "Pending Approved", value: 1 },
    { name: "Pending", value: 2 },
  ];
  typeValue: any = 1;
  connection: any;
  receipt: any;
  riderStatus: any;
  ngOnInit() {
    this.connectSocket();
    this.load();
    this.loadTimerState();
    this.startTimer();
    this.apiService.getDashboardStatus().subscribe((data) => {
      this.showOrders = false;
    });
  }
  connectSocket() {
    this.connection = this.socketService
      .on("autoSettlement")
      .subscribe((result: any) => {
        this.load();
      });
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
        this.onRefresh();
      });
    this.socketService.on("reconnect").subscribe((result: any) => {
      this.onRefresh();
    });
    this.socketService.on("webService").subscribe((result: any) => {
      this.load();
    });
    this.socketService.on("tableTransfer").subscribe((result: any) => {
      this.load();
    });
    this.socketService.on("pushDeliverAgent").subscribe((result: any) => {
      // Currently dont have order Id so providing rider status on all orders UI
      this.riderStatus = result;
    });
  }
  ngOnDestroy() {
    this.connection.unsubscribe();
    this.saveTimerState();
    this.stopTimer();
  }

  toggleTimePicker(transactionId: string) {
    this.timePickers[transactionId] = !this.timePickers[transactionId];
  }

  selectTime(time: string, transactionId: string) {
    this.processingTimeinput = time;
    this.timePickers[transactionId] = false;
  }
  onTimeInputChange(event: any) {
    const inputValue = event.target.value;
    const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    if (!timePattern.test(inputValue)) {
      // Reset the input if it doesn't match the format
      this.processingTimeinput = "00:00";
    }
  }
  load() {
    const intsetting: any = localStorage.getItem("currency");
    environment.currency = intsetting;

    this.apiService.getTableListWithKOT().subscribe(
      (result: any) => {
        // this.tableArr = result.sort(this.customCompare);
        this.tableArr = result;

        this.getUniqueTableGroup();
        // Check if there is only one table in the array
        if (this.tableArr.length === 1) {
          const table = this.tableArr[0];

          this.router.navigate(["/rst-invoice/" + table.id], {
            queryParams: {
              pl:
                table.pricelist_id && table.pricelist_id != null ? true : false,
              plid: (table.pricelist_id && table.pricelist_id) || null,
              grpn: table.group_name,
              isPanel4: true,
            },
          }); // Set 'resInvoice' to true in localStorage
          localStorage.setItem("isRstInvoice", "true");
        } else {
          localStorage.setItem("isRstInvoice", "false");
        }
      },
      (result: any) => {
        notyf.error("unable to load data");
      }
    );

    // this.apiService.getUnpaidInvoice().subscribe({
    //   next: (result: any) => {
    //     // Handle the successful response here
    //     // You can update your component state or perform other actions here
    //   },
    //   error: (err: any) => {
    //     // Handle errors here
    //     console.error("Error fetching unpaid invoices:", err);
    //     // Optionally, display an error message to the user or take other actions
    //   },
    // });
  }

  customCompare(a: any, b: any): number {
    const nameA = a.group_name.toLowerCase();
    const nameB = b.group_name.toLowerCase();
    if (nameA === nameB) {
      return 0;
    }
    const numericPartA = parseInt(nameA, 10);
    const numericPartB = parseInt(nameB, 10);
    if (!isNaN(numericPartA) && !isNaN(numericPartB)) {
      return numericPartA - numericPartB;
    }
    return nameA.localeCompare(nameB);
  }

  onRefresh() {
    if (this.showOrders) {
      this.refreshOrderList();
    } else {
      this.load();
    }
  }
  getUniqueTableGroup() {
    this.tableGroup = this.tableArr
      .map((table: any) => table.group_name)
      .filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      );
  }

  gotoInvoice(table: any) {
    this.router.navigate(["/rst-invoice/" + table.id], {
      queryParams: {
        pl: table.pricelist_id && table.pricelist_id != null ? true : false,
        plid: (table.pricelist_id && table.pricelist_id) || null,
        grpn: table.group_name,
      },
    });
  }
  changeShowOrders() {
    this.showOrders = !this.showOrders;
    if (this.showOrders) {
      this.refreshOrderList();
    } else {
      this.load();
    }
  }
  getNotApprovedKot() {
    this.spinner.show();
    this.apiService.getSONotApproved().subscribe(
      (result: any) => {
        this.kotArr = result;
        // this.startTimer();
        this.spinner.hide();
      },
      (result: any) => {
        this.spinner.hide();
      }
    );
  }
  getNotFinishedKot() {
    this.spinner.show();
    this.apiService.getSONotFinished().subscribe(
      (result: any) => {
        this.spinner.hide();
        this.kotArr = result;
      },
      (result: any) => {
        this.spinner.hide();
      }
    );
  }

  convertFloat(val: any) {
    return parseFloat(val);
  }
  onOrderTypeChange(e: any) {
    this.refreshOrderList();
  }

  onApproveClick($event: any, kot: any) {
    if (
      kot.order_mode.toLowerCase() === "zomato" ||
      kot.order_mode.toLowerCase() === "swiggy"
    ) {
      const dialogRef = this.dialog.open(OrderConfirmationComponent, {
        width: "400px",
        data: {
          title: "Approve",
          preparationTime: 15,
          order_mode: kot.order_mode,
          trans_no: kot.trans_no,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const reqObj: any = {
            transaction_id: kot.transaction_id,
            merchant_id: 330,
            order_id: kot.order_id,
            prepartion_time: result.preparationTime,
          };
          if (result.approved) {
            this.apiService
              .approveWeraKot(kot.transaction_id, reqObj)
              .subscribe((result: any) => {
                if (result.success) {
                  this.refreshOrderList();
                  this.printKot(kot);
                }
              });
          }
        }
      });
    } else {
      $event.stopPropagation();
      this.apiService.approveKot(kot.transaction_id).subscribe(
        (result: any) => {
          if (result.success) {
            this.refreshOrderList();
            this.printKot(kot);
          }
        },
        (result: any) => {}
      );
    }
  }

  onRejectClick($event: any, kot: any) {
    if (
      kot.order_mode.toLowerCase() === "zomato" ||
      kot.order_mode.toLowerCase() === "swiggy"
    ) {
      const dialogRef = this.dialog
        .open(OrderConfirmationComponent, {
          width: "300px",
          data: {
            title: "Reject",
            order_mode: kot.order_mode,
            trans_no: kot.trans_no,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const zReqObj: any = {
              transaction_id: kot.transaction_id,
              merchant_id: 330,
              order_id: kot.order_id,
              rejection_id: result.rejection_id, //go to docs for rejection id and values
            };
            const sReqObj: any = {
              transaction_id: kot.transaction_id,
              merchant_id: 330,
              order_id: kot.order_id,
              remark: result.rejection_id, //go to docs for rejection id and values
            };
            const data =
              kot.order_mode.toLowerCase() === "swiggy" ? sReqObj : zReqObj;
            if (result.approved) {
              this.apiService
                .rejectWeraKot(kot.transaction_id, data)
                .subscribe((result: any) => {
                  if (result.success) {
                    this.refreshOrderList();
                  }
                });
            }
          }
        });
    } else {
      $event.stopPropagation();
      const reason = "";
      this.apiService.deleteSalesOrder(kot.transaction_id, reason).subscribe(
        (result: any) => {
          if (result.success) {
            this.refreshOrderList();
          }
        },
        (result: any) => {}
      );
    }
  }

  // onProcessingClick(kot_id) {
  //   this.spinner.show();
  //   this.apiService.markProcessingKot(kot_id)
  //     .subscribe((result: any) => {
  //       if (result.success) {
  //         this.spinner.hide();
  //         this.refreshOrderList()
  //       }
  //     }, (result: any) => {
  //       this.spinner.hide();
  //     });
  // }

  onProcessingClick(transactionId: number) {
    if (!this.processingTimeinput) {
      alert("Please enter processing time.");
      return;
    }

    this.spinner.show();

    const requestData = {
      transaction_id: transactionId,
      processingTime: this.processingTimeinput,
    };

    this.apiService.markProcessingKot(requestData).subscribe(
      (result: any) => {
        if (result.success) {
          this.spinner.hide();
          this.refreshOrderList();
        } else {
          console.error(result.message);
          this.spinner.hide();
        }
      },
      (error: any) => {
        console.error(error);
        this.spinner.hide();
      }
    );
  }

  onMarkPickUp(kot: any) {
    this.apiService.markOrderPickedUp(kot.transaction_id).subscribe(
      async (result: any) => {
        if (result.success) {
          // -----invoice -----
          this.invData = await this.GetInvoiceData(
            result.data.vendorOrderDetails,
            result.data.customerDetails,
            result.data.itemsArr,
            kot
          );
          if (this.invData) {
            this.apiService.addInvoice(this.invData).subscribe(
              (result: any) => {
                if (result.success) {
                  this.invData.transaction_id = result.transaction_id;
                  this.invData.display_trans_no = result.display_trans_no;
                  this.invData.trans_type = "Invoice";
                  this.apiService.downloadPdf(this.invData).subscribe(
                    (result: any) => {
                      let blob = new Blob([result], {
                        type: "application/pdf",
                      });
                      var link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download =
                        "invoice" + this.invData.display_trans_no + ".pdf";
                      link.click();
                      window.URL.revokeObjectURL(link.href);
                    },
                    (result: any) => {}
                  );
                  notyf.success("New Invoice added successfully");
                  // this.getPendingInvoice()
                } else {
                  window.scroll(0, 0);
                  // notyf.error(result.message);
                }
              },
              (result: any) => {
                notyf.error("unable to add invoice");
              }
            );
          } else {
            notyf.error("unable to add invoice");
          }
          // -----invoice -----
          this.refreshOrderList();
        }
      },
      (result: any) => {}
    );
  }

  foodReady(kot: any) {
    this.apiService.weraFoodReady(kot.transaction_id).subscribe(
      async (result: any) => {
        if (result.success) {
          this.refreshOrderList();
        }
      },
      (result: any) => {}
    );
  }

  cancelledOrder($event: any, kot: any) {
    $event.stopPropagation();
    const reason = "";
    this.apiService.deleteSalesOrder(kot.transaction_id, reason).subscribe(
      (result: any) => {
        if (result.success) {
          this.getNotApprovedKot();
        }
      },
      (result: any) => {}
    );
  }

  orderAutoAccepted($event: any, kot: any) {
    $event.stopPropagation();
    this.apiService.approveKot(kot.transaction_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.getNotApprovedKot();
          this.printKot(kot);
        }
      },
      (result: any) => {}
    );
  }

  async callRider(kot: any) {
    this.dialog
      .open(CallRiderComponent, {
        width: "400px",
        data: kot.transaction_id,
      })
      .afterClosed()
      .subscribe((result) => {
      });
  }
  onDeleveredClick(kot_id: any) {
    this.spinner.show();
    this.apiService.markFinishedKot(kot_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.spinner.hide();
          this.refreshOrderList();
        }
      },
      (result: any) => {
        this.spinner.hide();
      }
    );
  }
  refreshOrderList() {
    if (this.typeValue == 1) {
      this.getNotApprovedKot();
    }
    if (this.typeValue == 2) {
      this.getNotFinishedKot();
    }
  }

  printKot(kot: any) {
    this.apiService.downloadPdfByTransId(kot.transaction_id).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + kot.trans_no + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => {}
    );
  }

  startTimer() {
    // this.timer = TimerObservable.create(0, 1000);

    timer(0, 1000).subscribe(() => {
      // Check if the conditions are met for starting the timer
      if (
        this.kotArr &&
        Array.isArray(this.kotArr) &&
        this.kotArr.length > 0 &&
        this.kotArr.some(
          (kot) => kot.approved && kot.processing && !kot.finished
        )
      ) {
        // Update processing_time for each KOT and store in localStorage
        this.kotArr.forEach((kot) => {
          if (kot.approved && kot.processing && !kot.finished) {
            const processingTime = this.calculateNewProcessingTime(
              kot.trans_no,
              kot.processing_time
            );
            kot.processing_time = processingTime;

            // Check if processing time has reached "00:00:00"
            if (processingTime === "00:00") {
              // Set the background color to red when time reaches "00:00:00"
              kot.backgroundColor = "#FFCCCB";
            } else {
              // Reset the background color if the condition is not met
              kot.backgroundColor = "";
            }
          }
        });

        // Save the updated KOT data in localStorage
        this.saveTimerState();
      }
    });
  }

  calculateNewProcessingTime(trans_no: string, currentTime: string): string {
    const previousTime =
      localStorage.getItem(`kot_${trans_no}_processing_time`) || currentTime;

    if (previousTime) {
      const [hours, minutes, seconds] = previousTime.split(":").map(Number);

      const totalSeconds = hours * 3600 + minutes * 60 + seconds;

      if (totalSeconds <= 0) {
        return "00:00";
      }

      const newTotalSeconds = totalSeconds - 1;

      const newHours = Math.floor(newTotalSeconds / 3600);
      const remainingSecondsAfterHours = newTotalSeconds % 3600;
      const newMinutes = Math.floor(remainingSecondsAfterHours / 60);
      const newSeconds = remainingSecondsAfterHours % 60;

      const formattedNewHours = newHours.toString().padStart(2, "0");
      const formattedNewMinutes = newMinutes.toString().padStart(2, "0");
      const formattedNewSeconds = newSeconds.toString().padStart(2, "0");

      // Store the updated processing time in localStorage
      localStorage.setItem(
        `kot_${trans_no}_processing_time`,
        `${formattedNewHours}:${formattedNewMinutes}:${formattedNewSeconds}`
      );
      return `${formattedNewMinutes}:${formattedNewSeconds}`;
    } else {
      return `${0}:${0}`;
    }
  }

  // Add a function to save the timer state in localStorage
  saveTimerState() {
    localStorage.setItem("kotArr", JSON.stringify(this.kotArr));
  }

  // Add a function to load the timer state from localStorage
  loadTimerState() {
    const savedKotArr = localStorage.getItem("kotArr");
    if (savedKotArr !== null && savedKotArr !== undefined) {
      this.kotArr = JSON.parse(savedKotArr);
    } else {
      this.kotArr = [];
    }
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  GetInvoiceData(data: any, cus_number: any, itemsArr: any, kot: any) {
    const totalTax = (data.cgst ? data.cgst : 0) + (data.sgst ? data.sgst : 0);
    var inv: any = {};
    inv.trans_date = kot.trans_date;
    inv.ledger_id = 0;
    inv.items_details = itemsArr;
    inv.table_id = kot.table_id;
    // inv.table_id = _tableID;
    inv.total_amount = data.net_amount;
    inv.total = null;
    inv.total_tax = totalTax;
    inv.sale_type = "cash";
    inv.taxslab = data.sgst_percent + data.cgst_percent;
    inv.dis_per = 0;
    inv.discount_amt = data.discount;
    inv.sgst_per = data.sgst_percent;
    inv.sgst_amt = data.sgst ? data.sgst : 0;

    inv.cgst_per = data.sgst_percent;
    inv.cgst_amt = data.cgst ? data.cgst : 0;

    inv.igst_per = 0;
    inv.igst_amt = data.igst ? data.igst : 0;
    inv.gross_amount = data.gross_amount;
    inv.taxable_amt = data.net_amount - totalTax;

    inv.roundOffAmt = 0;
    inv.roundoff_sign = null;
    inv.net_amount = data.net_amount;
    inv.tax = null;
    inv.soIdArr = [kot.transaction_id];
    inv.customer_name = "Name";
    inv.contact_no = cus_number.number || 0;
    inv.gstin = 0;
    inv.invoiceTransactionId = null;
    // inv.website = true
    inv.advance = 0;
    inv.cash = 0;
    inv.card = 0;
    inv.upi = 0;
    inv.orderMode = data.order_from;
    inv.shipping_address1 = null;
    inv.shipping_address2 = null;
    inv.shipping_street = null;
    inv.shipping_city = null;
    inv.shipping_state = null;
    inv.shipping_country = null;
    inv.shipping_pin = null;
    inv.card_ref_no = null;
    inv.tax_mode = "GST";
    inv.order_mode = data.order_from;
    return inv;
  }

  // TenentNavComponent(){
  //   this.dialog.open(TenentLoginComponent)
  // }

  openKOTModal(table: any): void {
    // Open the modal with initial data
    const dialogRef = this.dialog.open(KotViewComponent, {
      width: "650px",
      data: { table }, // Pass the table data initially
    });

    // Make the API call to fetch additional data
  }
  // Method to open Invoice modal
  openInvoiceModal(table: any): void {
    this.dialog.open(DashboardInvoiceComponent, {
      width: "700px", // Adjust size as needed
      data: { table }, // Pass data if needed
    });
  }

  receiptDialog(e: any) {
    if (e.inv_count == 1) {
      const dialogRef: MatDialogRef<ReceiptDialogComponent> = this.dialog.open(
        ReceiptDialogComponent,
        {
          data: e.display_trans_no,
          width: "600px",
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.load();
        }
      });
    } else {
      this.gotoInvoice(e);
    }
  }
}
