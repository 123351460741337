import { Component, Inject, OnInit, HostListener  } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../common.module';

@Component({
  selector: 'app-items-dialog',
  templateUrl: './items-dialog.component.html',
  styleUrls: ['./items-dialog.component.css'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class ItemsDialogComponent implements OnInit {


  selectedIndex: number = 0;
  variantSelected: boolean = false;
  checkedOprionAmount: number = 0;
  checkedOprionArr: any = [];
  checkedVariantAmount: number = 0;
  checkedVariant: any;
  itmData: any = [];
  optionsArr: any = [];
  variantsArr: any = [];
  sRate: number = 0;
  defaultVariantSize: any;
  totalAmt: number = 0;
  currency: any;
  isTransactionGSTslab: boolean = false;
  isLocal: boolean = false;
  status: any = "add"
  constructor(private dialogRef: MatDialogRef<ItemsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any = []) {
    this.currency = environment.currency;
    this.status = data.status ? data.status : "add"
    if (this.status === "add") {
      this.itmData = data.item
      this.initialized()
    } else {
      this.itmData = data.item
      this.updatedRowData(this.itmData)
    }

  }

  ngOnInit() {
    this.load();
    
    // Ensure the first variant is selected by default if there are any variants
    if (this.variantsArr.length > 0 && this.variantsArr[0]?.variants.length > 0) {
      this.defaultVariantSize = this.variantsArr[0].variants[0];
      this.variantSelected = true;
      this.onChangeChildVariant();  // Trigger the variant change logic
    }
  }

  load() {
    const isTransaction = localStorage.getItem("isTransactionGSTslab");
    this.isTransactionGSTslab = isTransaction == "false" ? false : true;
    const isLoc = localStorage.getItem("isLocal");
    this.isLocal = isLoc == "false" ? false : true;
    // if (this.data.status && this.data.status !== "update") {
    if (this.itmData.variant_groups.length)
      this.onChangeChildVariant();
    if (this.itmData.options.length)
      this.extractCheckedOptions();

    this.calculateSOAmount();
    // }
  }

   // Handle keyboard events
   // Handle keyboard events
   @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.selectedIndex = (this.selectedIndex + 1) % (this.variantsArr[0]?.variants?.length || 1);
      this.defaultVariantSize = this.variantsArr[0]?.variants[this.selectedIndex];
      this.onChangeChildVariant();
    } else if (event.key === 'ArrowUp') {
      this.selectedIndex = (this.selectedIndex - 1 + (this.variantsArr[0]?.variants?.length || 1)) % (this.variantsArr[0]?.variants?.length || 1);
      this.defaultVariantSize = this.variantsArr[0]?.variants[this.selectedIndex];
      this.onChangeChildVariant();
    } else if (event.key === 'Enter') {
      // Ensure a variant is selected before proceeding
      if (!this.variantSelected && this.variantsArr.length > 0) {
        this.defaultVariantSize = this.variantsArr[0].variants[0];
        this.variantSelected = true;
        this.onChangeChildVariant();
      } else if (this.variantSelected) {
        this.addItems();  // Add the item
        this.dialogRef.close({ item: this.itmData, status: this.status });  // Only close after adding the item
      }
    }
  }

   // Method to focus on the quantity input in the parent component
  focusOnQuantityInput() {
    const quantityInput = document.querySelector('#qty1') as HTMLInputElement;
    if (quantityInput) {
      quantityInput.focus();
    }
  }


  initialized() {
    this.defaultVariantSize = this.itmData.variant_groups.length ? this.itmData.variant_groups[0].variants.filter((variant: any) => variant.is_default)[0] : null
    this.itmData.variant_groups.length ? this.itmData.variant_groups[0].variants
      .filter((e:any) => e.is_default && e.in_stock)
      .forEach((e:any) => {
        this.sRate += parseFloat(e.price);
      }) : [];
    this.sRate = this.sRate > 0 ? this.sRate : this.itmData.s_rate;
    // this.itmData.options[0].childOptions
    //   .filter((e:any) => {
    //     if (e.is_default && e.in_stock) {
    //       this.sRate = this.sRate + parseFloat(e.amount);
    //     }
    //   });
    this.optionsArr = this.itmData.options;
    this.variantsArr = this.itmData.variant_groups;
    this.optionsArr = this.optionsArr.map((option:any) => ({
      ...option,
      childOptions: option.childOptions.map((child:any) => ({
        ...child,
        ischecked: child.in_stock === true ? child.is_default : false,
      })),
    }));
  }

  async calculateSOAmount() {
    const optionAmount = await this.calculateOptionAmount()
    this.checkedVariantAmount = this.defaultVariantSize ? parseFloat(this.defaultVariantSize.price) : 0
    // this.totalAmt = this.checkedOprionAmount || 0 + this.checkedVariantAmount || 0

    const salesRate = this.defaultVariantSize && this.defaultVariantSize.price > 0 ? parseFloat(this.defaultVariantSize.price) : this.itmData.s_rate;
    this.totalAmt = this.checkedOprionAmount + salesRate
  }
  onChangeChildVariant() {

    this.checkedVariant = this.variantsArr.map((e:any) => {
      return { ...e, variants: [this.defaultVariantSize] };
    });
    this.checkedVariantAmount = this.defaultVariantSize ? parseFloat(this.defaultVariantSize.price) : 0
    this.calculateSOAmount()
  }


  onChangeAddons($event:any, checkedId: any) {
    this.optionsArr.forEach((p:any) => {
      p.childOptions.map((e:any) => {
        if (e.id == checkedId) {
          e.ischecked = $event
        }
      })
    })
    this.calculateSOAmount()
    this.extractCheckedOptions()
  }


  calculateOptionAmount() {
    let oprionAmount = 0
    // if (this.isTransactionGSTslab) {
    this.optionsArr.forEach((p:any) => {
      p.childOptions.forEach((e:any) => {
        if (e.ischecked == true && e.amount > 0 && e.in_stock)
          oprionAmount += parseFloat(e.amount)
      })
    })
    // } else {
    //   this.optionsArr.forEach(p => {
    //     p.childOptions.forEach((e:any) => {
    //       if (e.ischecked == true && e.amount > 0 && e.in_stock) {

    //         if (this.isLocal) {
    //           e.sgst_per = e.taxslab / 2;
    //           e.sgst_amt = e.amount * (e.sgst_per / 100) || 0;
    //           e.sgst_amt = Math.round(e.sgst_amt * 100) / 100;
    //           e.cgst_per = e.taxslab / 2;
    //           e.cgst_amt = e.amount * (e.cgst_per / 100) || 0;
    //           e.cgst_amt = Math.round(e.cgst_amt * 100) / 100;
    //         } else {
    //           e.igst_per = e.taxslab;
    //           e.igst_amt = e.amount * (e.igst_per / 100) || 0;
    //           e.igst_amt = Math.round(e.igst_amt * 100) / 100;
    //         }

    //         e.total_tax = (e.sgst_amt || 0) + (e.cgst_amt || 0) + (e.igst_amt || 0);
    //         e.total = parseFloat(e.amount) + e.total_tax;
    //         oprionAmount += (parseFloat(e.amount) + e.total_tax)
    //       }
    //     })
    //   })
    // }
    this.checkedOprionAmount = oprionAmount
    return oprionAmount
  }

  addItems() {
    this.extractCheckedOptions();
    this.calculateSOAmount();
    this.itmData.optionAmount = this.checkedOprionAmount;
    this.itmData.optionsData = this.checkedOprionArr;
    this.itmData.checkedVariantAmount = this.checkedVariantAmount;
    this.itmData.checkedVariant = this.checkedVariant;
    this.itmData.qty = this.status == "update" ? (this.itmData.qty ? this.itmData.qty : 1) : 1;
    this.itmData.s_rate = this.checkedVariantAmount && this.checkedVariantAmount > 0 ? this.checkedVariantAmount : this.itmData.s_rate
    this.dialogRef.close({ item: this.itmData, status: this.status });
    this.load()
    this.itmData.sitm = '';
  }


  extractCheckedOptions() {
    this.checkedOprionArr = this.optionsArr.map((option:any) => ({
      ...option,
      childOptions: option.childOptions.filter((childOption:any) => childOption.ischecked == true && childOption.in_stock == true)
    }));

  }
  // -------------------------update customisable -------------


  updatedRowData(itmdata:any) {
    this.sRate = itmdata.s_rate;
    this.optionsArr = this.extractCheckedOptionsData(itmdata);
    this.variantsArr = this.extractCheckedVariantsData(itmdata);
    this.defaultVariantSize = this.variantsArr && this.variantsArr.length ? this.variantsArr[0].variants.filter((variant: any) => variant.ischecked)[0] : null
  }






  extractCheckedOptionsData(item:any) {
    const updatedArray = item.options && item.options.length
      ? item.options.map((option:any) => {
        return {
          ...option,
          childOptions: option.childOptions.map((child:any) => ({
            ...child,
            ischecked: item.optionsData && item.optionsData.length
              ? item.optionsData
                .find((addedOption:any) => addedOption.id === option.id)
                .childOptions.some((addedChild:any) => addedChild.id === child.id)
              : false,
          })),
        };
      })
      : [];
    return updatedArray;
  }
  extractCheckedVariantsData(item:any) {
    const updatedArray = item.variant_groups && item.variant_groups.length
      ? item.variant_groups.map((option:any) => {
        return {
          ...option,
          variants: option.variants.map((child:any) => ({
            ...child,
            ischecked: item.checkedVariant && item.checkedVariant.length
              ? item.checkedVariant
                .find((addedVariants:any) => addedVariants.id === option.id)
                .variants.some((addedChild:any) => addedChild.id === child.id ? true : false)
              : false,
          })),
        };
      })
      : [];
    return updatedArray;
  }


}
