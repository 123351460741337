export const environment = {
  //  basePath : 'http://localhost:3300/',
  recaptchaSiteKey:"6LduiA4qAAAAADZwlHk0sPq2yMJi1UWRNOXPnpYb",

  //basePath: '',
  //urlForSocket:'http://intellibookstesting.intellibooks.cloud',
  basePath: 'https://api.intellibooks.io',
  urlForSocket:'https://api.intellibooks.io',

  currency: 'inr',
  production: true,
  isLicence: false,
  use: function () {
    var currency = localStorage.getItem('currency');
    var isLicence = localStorage.getItem('licence');
    if (currency)
      this.currency = currency;
    // if (isLicence)
    //   this.isLicence = isLicence;
  }
};
environment.use();
